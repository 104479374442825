import Store from '@store';
import { Auth, Hub } from 'aws-amplify';
import RulesLoader from '@src/rules';
import { Logger } from '@src/lib/amplify';
import {
  clearStorage,
  localPath,
  setLocalPath,
  signOut,
  awsAuthConfig,
  getUserDisplayName,
} from './commons/hostedUi';

const { commit, getters } = Store;
const logger = new Logger('SignIn');

const authUser = async (next) => {
  await RulesLoader.load();

  const user = await Auth.currentAuthenticatedUser({
    bypassCache: true,
  });

  const userSession = user.signInUserSession.idToken;

  commit('user/setId', userSession.payload.sub);

  commit('user/setGroups', userSession.payload['cognito:groups']);

  const displayName = getUserDisplayName(user.attributes);

  const attributes = {
    ...user.attributes,
    name: displayName,
  }

  commit('user/setAttributes', {
    id: userSession.payload.sub,
    ...attributes,
  });

  window.analytics.identify(userSession.payload.sub, {
    name: attributes.name,
    email: attributes.email,
    phone: attributes.phone_number || null,
    groups: userSession.payload['cognito:groups'] || [],
  });

  const localUrl = localPath();

  if (localUrl) {
    clearStorage();
    next({ path: localUrl });
  } else {
    next({ path: '/dashboard' });
  }
  next();
};

const hubHandler = async (result, routerNext) => {
  const { payload: { event, data } } = result;

  switch (event) {
    case 'signIn':
      logger.info('user signed in', data.username);

      await authUser(routerNext);

      break;
    case 'signOut':
      logger.info('user signed out', data.username);

      await signOut();

      break;
  }
}

const beforeRouteEnter = async (to, from, next) => {
  await Auth.configure({ oauth: awsAuthConfig() });

  if (getters['user/id']) {
    const localUrl = await localPath();

    clearStorage();
    next({
      path: localUrl || '/dashboard',
    });
  } else if (!to.query.code) {
    await Auth.federatedSignIn();
  } else {
    Hub.listen('auth', (result) => hubHandler(result, next));
    next();
  }
};

export default {
  name: 'HostedUi',
  beforeRouteEnter,
  render(h) {
    return h('div', {
      attrs: {
        class: '__loader text-center',
      }
    }, [
      h('div', {
        attrs: {
          class: '__loader-image',
        }
      })
    ]);
  }
}
